<template>
  <div class="CANCELED" />
  <!--
  <base-section id="sgdPresentation">
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <base-img
          :src="require(`@/assets/imgs/sgd/logo.png`)"
          color="grey lighten-1"
          max-width="96"
          tile
        />
      </v-row>
    </v-container>

    <base-section-heading
      title=" "
      outlined
    >
      {{ $i18n.t('title') }}
      <br>
      <base-btn
        class="mt-12"
        outlined
        @click="openPage"
      >
        {{ $i18n.t('more') }}
      </base-btn>
    </base-section-heading>

    <v-container>
      <v-row>
        <v-col
          v-for="n in 3"
          :key="n"
          cols="12"
          sm="6"
          md="4"
        >
          <v-hover>
            <template v-slot="{ hover }">
              <base-img
                style="cursor: pointer"
                :height="$vuetify.breakpoint.mdAndUp ? 400 : 300"
                :src="require(`@/assets/imgs/sgd/img-${n}.png`)"
                color="grey lighten-1"
                flat
                tile
                @click="openPage"
              >
                <v-overlay
                  :value="hover"
                  absolute
                  class="pa-6"
                  color="secondary"
                  opacity=".9"
                >
                  <base-subheading :title="fullCards[n-1].title" />

                  <base-body>{{ fullCards[n-1].text }}</base-body>
                </v-overlay>
              </base-img>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
  -->
</template>

<script>
  export default {
    name: 'SgdPresentation',
    // *************
    // ** DATA    **
    // *************
    data: () => ({
      cards: 9,
    }),
    computed: {
      fullCards () {
        const nCards = []
        for (let i = 0; i < this.cards; i++) {
          nCards.push({
            title: this.$i18n.t(`t${i}.title`),
            text: this.$i18n.t(`t${i}.text`),
          })
        }
        return nCards
      },
    },
    methods: {
      openPage () {
        window.open('https://www.sgdgroup.it/', '_blank')
      },
    },
  }
</script>
<i18n>
{
  "en": {
    "more": "Find out more",
    "t0": {
      "text": "Installation of misting systems, also with natural repellent, against mosquitoes",
      "title": "Mosquito repellent systems"
    },
    "t1": {
      "text": "Fixed systems with nozzles and misting fans",
      "title": "Cooling systems"
    },
    "t2": {
      "text": "Water potability analysis, buffers on surfaces and microbiological analysis of agro-food substances",
      "title": "Chemical and microbiological analysis"
    },
    "title": "SGD Group operates throughout the whole national territory, being present in the sector of  disinfections and pests monitoring with almost 50 years of experience.  It is indeed certified ISO 9001, ISO 14001, ISO 45001, UNI EN 16636 and BPP (Bayer Protection Program)."
  },
  "it": {
    "more": "Per saperne di più",
    "t0": {
      "text": "Installazione impianti a nebulizzazione, anche con repellente naturale, contro le zanzare",
      "title": "Impianti antizanzare"
    },
    "t1": {
      "text": "Impianti fissi con ugelli e ventilatori a nebulizzazione",
      "title": "Impianti di raffrescamento"
    },
    "t2": {
      "text": "Analisi potabilità acqua, tamponi su superfici e analisi microbiologiche di sostanze agro-alimentari",
      "title": "Analisi chimica e microbiologica"
    },
    "title": "SGD Group, attiva in tutto il territorio nazionale, è presente nel settore delle disinfestazioni e del monitoraggio degli infestanti con quasi 50 anni di esperienza. E’ certificata ISO 9001, ISO 14001, ISO 45001, UNI EN 16636 e BPP (Bayer Protection Program)."
  }
}
</i18n>
